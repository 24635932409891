import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams, useLoaderData, useLocation } from "react-router-dom";
import "../styles/App.css";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { useEffect, useState } from "react";
import Config from "../config";
import EquipmentCard from "../components/Pages/Layout/equipmentCard";

function LoanInfromation(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const [saveState, setSaveState] = useState(true);
  const data: any = useLoaderData();
  let selectedEquipments: any = [];
  let namesSet = new Set();
  let totalPrice = data.equipments.totalPrice.value;
  let totalDiscount = data.equipments.totalDiscount.value;
  let totalDiscountFormatted = `$${totalDiscount.toFixed(2)}`;
  
  if (data.equipments) {
    // Filter out duplicates
    data.equipments.items.forEach((item: { name: unknown; price: string; }) => {
      if (!namesSet.has(item.name)) {
        selectedEquipments.push(item);
        namesSet.add(item.name);
        totalPrice = item.price;
      }
    });
  
    totalDiscount = data.equipments.totalDiscount.value;
  }

  const [formData, setFormData] = useState({
    downpayment: '',
    term: '',
    taxes: '',
  });
  let defaultAddress = (data.businessAddress && data.businessCity && data.businessState && data.businessZip) 
  ? data.businessAddress + ", " + data.businessCity + ", " +  data.businessState + ", " + data.businessZip 
  : '';

  const [isCorporateOnly, setIsCorporateOnly] = useState(data.corpApplication || false);

  const formSchema: FieldAttributes[] = [
    {
      name: "downpayment",
      label: "Est. Downpayment",
      type: FieldType.CURRENCY,
      defaultValue: data.downpayment ? data.downpayment  : "$0",
      validation: {required: false},
      /* validation: saveState ? { required: false } : { required: true }, */
    },
    {
      name: "term",
      label: "Est. Term",
      type: FieldType.SELECT,
      defaultValue: data.term,
      mode: "SELECT",
      validation: { required: true },
      options: [
        { label: "60 months", value: "60 months" },
        { label: "24 months", value: "24 months" },
        { label: "36 months", value: "36 months" },
        { label: "48 months", value: "48 months" },
        { label: "72 months", value: "72 months" },
      ],
    },
    {
      name: "financePlan",
      label: "Finance Plan",
      type: FieldType.SELECT,
      defaultValue: data.financePlan ? data.financePlan : "standard",
      mode: "SELECT",
      validation: { required: true },
      options: [
        { label: "Standard", value: "standard" },
        { label: "90 Day Delay", value: "90daydelay" },
      ],
    },
/*     {
      name: "taxes",
      label: "Est. Taxes",
      type: FieldType.CURRENCY,
      defaultValue: data.taxes ? data.taxes : "$0",
      validation: { required: false },
    }, */
    {
      name: "taxExempt",
      label: "My business is tax exempt",
      type: FieldType.CHECKBOX,
      defaultValue: data.taxExempt,
      validation: { required: false },
    },
    {
      name: "corpApplication",
      label: "Submit this application with business credit only",
      type: FieldType.CHECKBOX,
      defaultValue: data.corpApplication,
      validation: { required: false },
    },
    {
      name: "businessAddress",
      label: "Equipment Storage Location",
      type: FieldType.ADDRESS,
      defaultValue: data.equipmentLocation
        ? data.equipmentLocation
        : data.businessAddress,
      validation: saveState ? { required: false } : { required: true },
    },
  ];
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any) => {
    if (methods.formState.isValid) {
      const nonOwnerTypes = ["government", "nonprofit", "tribal", "other"];
      if (nonOwnerTypes.includes(data.businessType?.toLowerCase())) {
        navigate(`/${params.hash}/authorized-signer-information`);
      } else {
        if (values.corpApplication) {
          navigate(`/${params.hash}/corporate-application`);
        } else {
          navigate(`/${params.hash}/sole-owner-information`);
        }
      }
    }
    
    const saveApplicationStep = async (values: any) => {
      if (!saveState && methods.formState.isValid) {
        setIsLoading(true);
      }
      let names = data.equipments.items.map((item: { name: any; }) => item.name).join(', ');
      const payload = {
        Status: ["LOAN_INFO"],
        token: params.hash,
        maxDownpayment: values.downpayment,
        loanTerms: values.term,
        product: names ? names : null,
        salesTaxExempt: values.taxExempt ? true : false,
        //salesTax: values.taxes,
        funnelType: values.corpApplication ? 0 : undefined,
        corpApplication: values.corpApplication ? true : false,
        shippingAddress: values.businessAddress,
        financePlan: values.financePlan,
        currentFunnelPath:
          saveState === true ? (data.currentStep = 2) : (data.currentStep = 3),
        /*           saveState === true
            ? data.currentStep
            : data.currentStep <= 2
            ? data.currentStep + 1
            : data.currentStep, */
      };
      if (values.corpApplication) {
        payload.funnelType = 0;
      }
      try {
        fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    saveApplicationStep(values);
  };

  const saveHandler = async (values: any) => {
    setSaveState(true);
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    methods.trigger();
    setSaveState(false);

  };

  const handleInputChange = (event: any) => {
    const { name, checked, value } = event.target;
    if (name === "corpApplication") {
      setIsCorporateOnly(checked);
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setSaveState(false);
    setFormHeader("Great job! Now set up your application");
    setProgressHeader(true);
    setFormProgress(`w-[50%]`);
    setFormProgressText("50%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(false);
  }, []);

  let totalMinus = 0
  if (formData.downpayment || data.downpayment) {
    totalMinus = parseFloat((formData.downpayment ? formData.downpayment : data.downpayment).replace(/[$,]/g, ""))
    }
  const totalFinanced = data.equipments.totalPrice.value - (data.equipments.totalDiscount.value ? data.equipments.totalDiscount.value : 0)
  //const totalFinanced = parseFloat(totalPrice.replace(/[^0-9.-]+/g,"")) - (data.equipments.totalDiscount.value ? 0 : 0)

  return (
    <>
      <BackButton 
        lastPage={"business-information"}
        isLoading={isLoading}
      />
      <FormProvider {...methods}>
        <label className="text-[#171718] font-semibold pl-0 pb-2">
          Selected Equipment For Finance
        </label>
        {selectedEquipments.map((eq: any) => (
          <EquipmentCard
            key={eq.key}
            url={eq.avatar}
            brand={eq.brand}
            name={eq.name}
            description={eq.description}
            price={eq.price}
          />
        ))}
        <form className="form" onChange={handleInputChange} onSubmit={methods.handleSubmit(onSubmitHandler)}>
          {formSchema.map((field, index) => (
            <Field key={index} {...field} />
          ))}
          <div className="bg-[#F6F5F5] rounded p-4">
            <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
              <div className="text-[#727373]">Equipment Full Price</div>
              <div className="text-[#171718] font-semibold">{data.equipments.totalPrice.formated}</div>
            </div>
            <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
              <div className="text-[#727373]">Discount</div>
              <div className="text-[#171718] font-semibold">
                {totalDiscountFormatted.replace('.00', '')}
              </div>
            </div>
{/*             <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
              <div className="text-[#727373]">Est. Taxes</div>
              <div className="text-[#171718] font-semibold">{formData.taxes ? formData.taxes : data.taxes}</div>
            </div> */}
            <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
              <div className="text-[#727373]">Est. Downpayment</div>
              <div className="text-[#171718] font-semibold">
                -{formData.downpayment ? formData.downpayment : data.downpayment}
              </div>
            </div>
            <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
              <div className="text-[#727373]">Est. Term</div>
              <div className="text-[#171718] font-semibold">{formData.term ? formData.term : data.term}</div>
            </div>
            <div className="bg-neutral-300 h-px m-1" />
            <div className="flex flex-row justify-between w-full py-4 mobile-loan-container">
              <div className="text-[#171718] font-semibold">
                Total Financed Amount
              </div>
              <div className="text-[#171718] font-semibold">${(totalFinanced - totalMinus).toLocaleString('en-US')}</div>
            </div>
          </div>
          <span className="text-[#727373]">All figures are before applicable taxes</span>
          <ButtonContainer
            button1={"Save & Finish Later"}
            button2={isCorporateOnly ? "Next: Corporate Contacts" : "Next: Owner Contacts"}
            loading={isLoading ? true : false}
            navigate={navigateHandler}
            save={saveHandler}
          />
        </form>
      </FormProvider>
    </>
  );
}

export default LoanInfromation;