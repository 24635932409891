import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import Config from "../config";

function AuthInfo() {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data: any = useLoaderData();
  const [saveState, setSaveState] = useState(true);

  const formSchema: FieldAttributes[] = [
    {
      name: "nameOfAuthSigner",
      label: "Name of Authorized Contact",
      type: FieldType.TEXT,
      defaultValue: data.nameOfAuthSigner,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "titleOfAuthSigner",
      label: "Business Title",
      type: FieldType.TEXT,
      defaultValue: data.titleOfAuthSigner,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "emailOfAuthSigner",
      label: "Email Address",
      type: FieldType.EMAIL,
      defaultValue: data.emailOfAuthSigner,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "phoneOfAuthSigner",
      label: "Phone Number",
      type: FieldType.PHONE,
      defaultValue: data.phoneOfAuthSigner,
      validation: saveState ? { required: false } : { required: true },
    }
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      setIsLoading(true);
      const payload = {
        Status: ["AUTH_INFO"],
        token: params.hash,
        nameOfAuthSigner: values.nameOfAuthSigner,
        titleOfAuthSigner: values.titleOfAuthSigner,
        emailOfAuthSigner: values.emailOfAuthSigner,
        phoneOfAuthSigner: values.phoneOfAuthSigner,
        currentFunnelPath: saveState ? 4 : 5
      };
      
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
      return true;
    };

    const saved = await saveApplicationStep(values);
    if (methods.formState.isValid && saved) {
      navigate(`/${params.hash}/context`);
    }
  };

  const saveHandler = async () => {
    setSaveState(true);
    PopUpToggler();
  };

  const navigateHandler = async () => {
    setSaveState(false);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);
  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
  const [formProgress, setFormProgress] = useAtom(progressAtom);
  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);
  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);
  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);
  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setSaveState(false);
    setFormHeader("Let's verify the Authorized Signer contact information");
    setProgressHeader(true);
    setFormProgress("w-9/12");
    setFormProgressText("75%");
    setFormButtonShow("");
    setformDocuBar(false);
    setFormNarrow(true);
    methods.trigger();
  }, []);

  return (
    <>
      <BackButton lastPage={"finance-information"} />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
          {formSchema.map((field, index) => (
            <Field key={index} {...field} />
          ))}
          <ButtonContainer
            button1={"Save & Finish Later"}
            button2={"Next"}
            loading={isLoading ? true : false}
            navigate={navigateHandler}
            save={saveHandler}
          />
        </form>
      </FormProvider>
    </>
  );
}

export default AuthInfo;
